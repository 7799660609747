import "./App.css";
import Developer from "./Developer";
import "./style.css";

function App() {
  return (
    <div className="container">
      <Developer name=" Itallo" age={24} country=" Brasil" />
      <Developer name=" Jonas" age={34} country=" Alemanha" />
      <Developer name=" Fred" age={14} country=" Japao" />
    </div>
  );
}

export default App;
