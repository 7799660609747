import { Component } from "react";

class Developer extends Component {
  render() {
    return (
      <div className="cover">
        <h1>Dev:{this.props.name}</h1>
        <h3>Idade:{this.props.age}</h3>
        <h3>Pais:{this.props.country}</h3>
      </div>
    );
  }
}
export default Developer;
